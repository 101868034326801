import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import AboutUs from "../Screens/AboutUs/AboutUs";
import ContactUs from "../Screens/ContactUs/ContactUs";
import PrivacyPolicy from "../Screens/policy/PrivacyPolicy";
import TermsAndConditions from "../Screens/policy/TermsAndConditions";
import { ResidentialSection } from "../Screens/LandingPage/ResidentialSection/ResidentialSection";
import Layout from "../Layout/Layout";
import { CommercialSection } from "../Screens/LandingPage/CommercialSection/CommercialSection";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/residential`} component={ResidentialSection} />
          <Route exact path={`/commercial`} component={CommercialSection} />
          <Route exact path={`/about-us`} component={AboutUs} />
          <Route exact path={`/signup`} component={SigninSignup} />
          <Route exact path={`/contact`} component={ContactUs} />
          <Route exact path={`/privacypolicy`} component={PrivacyPolicy} />
          <Route exact path={`/termsandconditions`} component={TermsAndConditions}
          />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
