import React from "react";
import { Row, Col } from "antd";
import "./Sectors.scss";

export const Sectors = () => {
  return (
    <div className="sectors">
      <div>
        <Row
          gutter={[
            { xs: 8, sm: 20, md: 30, lg: 40 },
            { xs: 8, sm: 16, md: 10, lg: 10 },
          ]}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
           style={{backgroundColor:"#dcdce2a1", borderRadius:"10px"}}>
            <div>
              <h1>Buildr Audits Sectors</h1>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <span style={{ color: "red" }}> ✔</span> Residential{" "}
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> Commercial{" "}
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> Luxury Retail{" "}
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> Hospitality
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> HealthCare
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> Marine{" "}
              </li>
              <li>
                <span style={{ color: "red" }}> ✔</span> Aviation{" "}
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};
