import React from "react";
import { Row, Col } from "antd";
import "./ResidentialSection.scss";

export const ResidentialSection = () => {
  return (
    <div>
      <section className="residential-section">
        <h1>Residential Inspection Process</h1>
        <p className="content" align="center">
          Buildraudit helps our client right from indentifying the home right
          suited as in background check of the builder, track record,project
          status JV or outright ,future appreciation and quality of
          construction.
        </p>
        <div className="container mx-auto">
          <div className="new">
            <Row className="new_1">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                className="coloumn"
                align="center"
              >
                <img
                  src={require("../../../assets/images/residential/Residential-icon-1.png")}
                  alt=""
                  style={{ height: "150px", width: "200px", paddingTop: "5px" }}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
                className="col-rs"
              >
                <h3>home validation</h3>
                <p style={{ paddingBottom: "15px" }}>
                  While choosing a right home as per your choice,budget,location
                  and acess to malls, schools, hospitals is always a challenge
                  and we buildraudits can help you in assessing the right home
                  for you, as buildraudits team has the latest digital tools to
                  collaborate with our partners and experts to find you the home
                  just right for you to live and also appreciate in terms your
                  hard-earned investment.
                </p>
              </Col>
            </Row>
          </div>

          {/* start second-service */}
          <div className="new">
            <Row className="new_1">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                className="coloumn"
                align="center"
              >
                <img
                  src={require("../../../assets/images/residential/Residential-icon-2.png")}
                  alt=""
                  style={{ height: "150px", width: "200px" }}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
                className="col-rs"
                style={{ paddingBottom: "15px" }}
              >
                <h3>Construction Link Payments</h3>
                <p>
                  Help in signing sale agreement, tracking stage of construction
                  and facilitating construction link payments.
                </p>
              </Col>
            </Row>
          </div>
          {/* end second-service */}

          {/* start third-service */}
          <div className="new">
            <Row className="new_1">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                className="coloumn"
                align="center"
                style={{ paddingTop: "15px" }}
              >
                <img
                  src={require("../../../assets/images/residential/Residential-icon-3.jpg")}
                  alt=""
                  style={{ height: "150px", width: "150px" }}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
                className="col-rs"
              >
                <h3>Occupancy Certificate</h3>
                <p style={{ paddingBottom: "15px" }}>
                  Ensuring the project once duly gets OC, buildraudits
                  residential team help in completing the balance payment by
                  submitting audit report which comprises of compliance of fire
                  safety,child and elderly safety, operational club house,gym,
                  swimming pool,play area,parks,car parking, electrical,facility
                  management and building security.
                </p>
              </Col>
            </Row>
          </div>
          {/* end third-service */}

          {/* start forth-service */}
          <div className="new">
            <Row className="new_1">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                className="coloumn"
                align="center"
              >
                <img
                  src={require("../../../assets/images/residential/Residential-icon-4.png")}
                  alt=""
                  style={{ height: "150px", width: "200px" }}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
                className="col-rs"
                style={{ paddingBottom: "15px" }}
              >
                <h3>Possession and Handover</h3>
                <p>
                  Before the possesion and handover, there will be a 101 check points
                  right from auditing finishing,  fitouts,
                  electrical,plumbing,painting,faucets kitchens and safety
                  grills.
                </p>
              </Col>
            </Row>
          </div>
          {/* end forth-service */}
        </div>
      </section>
    </div>
  );
};
