import React from "react";
import { Row, Col } from "antd";
import "./CommercialSection.scss";
import cm from "../../../assets/images/cm-side.jpg";
import { CmSectors } from "../Cm_sectors/CmSectors";

export const CommercialSection = () => {
  return (
    <div className="commercial-section top-right-wave">
    <CmSectors />
      <Row>
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          md={{ span: "12" }}
          lg={{ span: "12" }}
          className="cm-cl-1"
        >
          <img src={cm} alt="side-img" />
        </Col>
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          md={{ span: "12" }}
          lg={{ span: "12" }}
          className="cm-cl-2"
        >
          <h1>Commercial Inspection Process</h1>
          <ul style={{ listStyle: "none" }}>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Soiltest
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Building Assessment
              Framework
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Structuring
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Cabling
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Electrical
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Sanitary
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Plumbing Systems
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Safety and Fire
              Protection
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Air Conditioning and
              Ventilation Systems
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Interiors
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Vertical Transport
              Systems- Elevators,Lifts,escalators
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Parking infrastructure
            </li>
            <li>
              <span style={{ color: "blue" }}> ✔</span> Building Security
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};
