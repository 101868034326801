import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import "./AboutUs.scss";
// import QuickLinks from "../QuickLinks/QuickLinks";
import mainlogo from "../../assets/images/about-us.png";

class AboutUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{ fontSize: "55px" }}>About Us</h1>
            <p>
              Buildraudits is the world’s first Digital building audit and
              inspection services company. Buildraudits team uses latest AI
              technology for precision building audit results.
            </p>
          </div>
        </section>
        {/* page banners */}
        {/* <section className="about-banners">
          <img
            src={require("../../assets/images/page-banners/about.jpg")}
            alt="About Us"
          />
        </section> */}
        {/* page banners */}

        <div className="inner-pages padding-40  light-bg">
          <div className="container mx-auto">
            <section id="about">
              <div>
                <Row
                  gutter={[
                    { xs: 8, sm: 20, md: 30, lg: 40 },
                    { xs: 8, sm: 16, md: 10, lg: 10 },
                  ]}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 16 }}
                    lg={{ span: 12 }}
                  >
                    <p style={{ color: "blue" }}>About Us</p>
                    <h1 style={{ fontSize: "55px" }}>Buildr Audits</h1>
                    <p style={{ fontSize: "19px" }}>
                      Buildraudits is the world’s first Digital building audit
                      and inspection services company. Buildraudits team uses
                      latest AI technology for precision building audit results.
                    </p>
                    <p style={{ fontSize: "19px" }}>
                      We can help to maintain and modernize your infrastructure
                      and solve various infrastructure-specific issues a
                      building or property may face.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                  >
                    <img src={mainlogo} alt="Buildr Audit" />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(AboutUs);
