import React, { Component } from 'react'
import { Select } from 'antd';

class Dropdwn extends Component{
  constructor(){
      super();
      this.state={
          DDL1:[],
          DDL2:[],
          selectddl:'',
      };
  }

    componentDidMount(){
        this.setState({
            DDL1:[
                {name:'Residential',DDL2:['Studio','1BHK','2BHK','3BHK','4BHK','Pent House','Villas','Villament','Row Houses','Loft','Boutique Apartments Residence','Serviced Residences','Boutique Residences','Hotel Residences','Luxury Residences','Bungalows']},
                {name:'Commercial',DDL2:['Business Centers','Offices','Co-working Offices','Serviced Offices','IT Parks','Business Park','Industrial Parks','Auto Parks','Food Parks','R and D Centers','E-com Parks']},
                {name:'Hospitality',DDL2:['7 Star Hotels','5 Star Hotels','3 Star Hotels','2 Star Hotels','1 star Hotels','Boutique Hotels','Branded Hotels','Resorts','Stays']},
                {name:'Healthcare',DDL2:['5 Start Hospitals','Multispecialty Hospitals','Super Specialty Hospitals','Diagnostic Centers','Pharma Centers']},
                {name:'Retail',DDL2:['Malls','Showrooms','Food Courts','Super Markets','Food Markets','Stores']},
                {name:'Luxury Retail',DDL2:['Jewel Souks','Boutiques','Lounges','Bars and Pubs']},
                {name:'Marine',DDL2:['Ports','Yacht Hubs']},
                {name:'Aviation',DDL2:['International Airports','Domestic Airports','Small Airports','Private Jet Lounges','Retail Space at Airports']},
            ]
        })
    }
  
    selectChange(e){
        this.setState({selectddl:e.target.value});
        this.setState({DDL2:this.state.DDL1.find(x => x.name===e.target.value).DDL2});
    }

    render(){
        return(
            <div>
             <select value={this.state.selectddl} onChange={this.selectChange.bind(this)}>
                 <option>--Select sector----</option>
                 {this.state.DDL1.map(x =>{
                     return<option>{x.name}</option>
                 })}
             </select>
             <select style={{marginTop:"10px"}}>
                 <option selected disabled>
                     ---Select--
                 </option>
                 {this.state.DDL2.map(x=>{
                     return<option>{x}</option>
                 })}
             </select>
            </div>

        );
    }
}

export default Dropdwn;