import React from "react";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import { Row, Col } from "antd";
import mainlogo from "../../assets/images/about-us.png";
import { Sectors } from "./SectorSection/Sectors";
import { ResidentialSection } from "./ResidentialSection/ResidentialSection";
import { CommercialSection } from "./CommercialSection/CommercialSection";
import InputBox from "../../Components/InputBox/InputBox";
import { Button} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Partners from "./Partners/Partners";
import Dropdwn from "../ContactUs/Dropdwn";

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultActiveKey: [],
      mobileNumber: "",
      fullName: "",
      emailId: "",
      phoneNumber: "",
      location:"",
    };
  }
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}

        {/* About Us  */}
        <section id="about">
          <div className="inner-pages padding-40  ">
            <Row
              gutter={[
                { xs: 8, sm: 20, md: 30, lg: 40 },
                { xs: 8, sm: 16, md: 10, lg: 10 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
              style={{paddingLeft:"90px"}}>
                <p style={{ color: "blue" }}>About Us</p>
                <h1 style={{ fontSize: "55px" }}>Buildr Audits</h1>
                <p style={{ fontSize: "19px" }}>
                  Buildraudits is the world’s first Digital building audit and
                  inspection services company. Buildraudits team uses latest AI
                  technology for precision building audit results.</p>
                  <p style={{ fontSize: "19px" }}>We can help to
                  maintain and modernize your infrastructure and solve various
                  infrastructure-specific issues a building or property may
                  face.
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
              >
                <img src={mainlogo} alt="Buildr Audit" />
              </Col>
            </Row>
          </div>
        </section>

        {/* Sectors */}
        <Sectors />

        {/* ResidentialSection */}
        <ResidentialSection id="rs"/>
    
         <CommercialSection id="cs"/>
            
         {/* <SigninSignup /> */}

          {/* start Contact-us */}
          <div className="signin-singup">
          {/* <div className="Contact-head">
            <p>Send Us Message</p>
            <h1 style={{ fontSize: "45px" }}>Contact Us</h1>
          </div> */}

          <Row gutter={[12, 20]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="Details-Section">
                <div className="Detail">
                  <h1>Contact Us</h1>
                  <div className="Details Address ">
                    <h1>Address</h1>
                    <p>
                      4th Floor, 402 - 406 Prestige Omega,<br></br>EPIP
                      Whitefield Bangalore- 560066
                    </p>
                  </div>
                  <div className="Details Call">
                    <h1>Call-Us</h1>
                    <p>(toll free)</p>
                  </div>
                  <div className="Details Mail">
                    <h1>Mail Us</h1>
                    <p>info@buildraudits.com</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              style={{ paddingLeft: "30px" }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="Contact-right"
            >
              <div className="title">
                <h1 align="center" style={{ fontSize: "45px" }}>
                  Contact Us
                </h1>
                {/* <p>Select Your Location To Help Us Serve You Better!</p> */}
              </div>
              <form>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Full Name"
                    id="fullName"
                    value={this.state.fullName}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Email"
                    id="emailId"
                    value={this.state.emailId}
                    onChangeText={this.onChangeText}
                    type="email"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Phone Number"
                    id="phoneNumber"
                    value={this.state.phoneNumber}
                    onChangeText={this.onChangeText}
                    type="tel"
                  />
                </div>
                <div className="form-fields">
                <Dropdwn/>
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Location Prefernce"
                    id="location"
                    value={this.state.location}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>
                <div className="form-fields">
                  <TextArea rows={4} placeholder="Comments" />
                </div>
                <div className="form-fields">
                  <Button type="primary">Submit</Button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
        {/* end Contact-us */}

        <Partners />
        
      </React.Fragment>
    );
  }
}
