import React from "react";
import "./CmSectors.scss";

// const Mainslidersettings = {
//   arrows: false,
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 5,
//   slidesToScroll: 1,
//   autoplay: false,
//   autoplaySpeed: 5000,
// };

export const CmSectors = () => {
  return (
    <div className="cmSectors">
      <h1>Commercial Sectors</h1>
      <div className="sector">
        <div className="sector-box">
          <div className="luxury-retail"></div>
          <h2>Retail</h2>
        </div>
        <div className="sector-box">
          <div className="hospitality"></div>
          <h2>Hospitality</h2>
        </div>
        <div className="sector-box">
          <div className="health-care"></div>
          <h2>Healthcare</h2>
        </div>
        <div className="sector-box">
          <div className="marine"></div>
          <h2>Marine</h2>
        </div>
        <div className="sector-box">
          <div className="aviation"></div>
          <h2>Aviation</h2>
        </div>
      </div>
    </div>
  );
};
