import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="quikjets-banner">
          <div className="quikjets-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item empty-legs-flight">
                  <Row justify="left" align="left">
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 20 }}
                      md={{ span: 20 }}
                      lg={{ span: 24 }}
                    >
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption s1c">
                            {/* <h3>Better for you and better for the plant</h3> */}
                            <h2>Building Assessment Framework & Structuring</h2>
                            <p>
                              Buildraudits has got best team of Commercial and
                              Architecture Engineers who have managed &
                              delivered many commercial projects and can assist
                              the best for you.
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item world-class-service">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>We are in different sectors</h3> */}
                            <h2>
                              Plumbing, drainage system and Cooling Units for
                              all Industries
                            </h2>
                            <p>
                              Buildraudits is in patnership with Plumber World
                              Wide, they have the best plumbers who can analyze
                              & assist linkages,drainage system and MVP
                              structures.
                            </p>
                            {/* 
                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item memership">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            <h2>
                              Electrical system, transformer unit & Power Grids
                            </h2>
                            <p>
                              Buildraudits is in partnership with Safe Electric
                              World Wide, they have the best electricians who
                              have deep Knowledge on commercial electric grids
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item slider_4">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            <h2>
                              Wall Inspection, Wall thickness & Wall Safety
                              Assessment
                            </h2>
                            <p>
                              Buildraudits Commercial auditing team have the
                              advanced tools and system to assist wall
                              thickness, wall density and wall safety
                              structures.
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item sl-5">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            <h2>Exhaust Systems and Cooling Units</h2>
                            <p>
                              Buildraudits team have the best engineers who can
                              analyze and assist the compatibility of the
                              cooling units and Exhaust sustems.
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item sl-6">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            <h2>Marine</h2>
                            <p>
                              Buildraudits marine team have delivered various
                              projects in public and private ports across the
                              world and have better Knowledge on analyzing and
                              assisting to a major ports.
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item sl-7">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <h3>Reduces single use plastic.</h3> */}
                            <h2>Aviation</h2>
                            <p>
                              Buildraudits Aviation team have the best engineers
                              who have Knowledge on all the aspects of aviation
                              ports and have capability to analyze and assist
                              safety structure for aviation ports
                            </p>

                            {/* <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
