import React from "react";
import keson from "../../../assets/images/partners/keson.png";
import leica from "../../../assets/images/partners/leica.png";
import bosch from "../../../assets/images/partners/bosch.png";
import technidea from "../../../assets/images/partners/technidea.png";
import topcon from "../../../assets/images/partners/topcon.png";
import ele from "../../../assets/images/partners/ele.png";
import sgs from "../../../assets/images/partners/sgs.png";
import mechman from "../../../assets/images/partners/mechman.png"
import plumbr from "../../../assets/images/partners/plumbr.png"
import safeelectric from "../../../assets/images/partners/safeelctric.jpg"
import "./Partners.scss";

const Partners = () => {
  return (
    <React.Fragment>
    <div className="partners">
      <div className="ptrn-title">
        <h1>Our Partners</h1>
      </div>
      <section id="partners">
        <div className="ptrn p-1">
          <img src={keson} alt="keson" />
        </div>
        <div className="ptrn p-2">
          <img src={leica} alt="leica" />
        </div>
        <div className="ptrn p-3">
          <img src={bosch} alt="bosch" />
        </div>
        <div className="ptrn p-4">
          <img src={technidea} alt="technidea" />
        </div>
        <div className="ptrn p-5">
          <img src={topcon} alt="topcon" />
        </div>
        <div className="ptrn p-6">
          <img src={ele} alt="ele" />
        </div>
        <div className="ptrn p-7">
          <img src={sgs} alt="sgs" />
        </div>
        <div className="ptrn p-8">
          <img src={plumbr} alt="plumbr" />
        </div>
        <div className="ptrn p-9">
          <img src={mechman} alt="mechman" />
        </div>
        <div className="ptrn p-10">
          <img src={safeelectric} alt="safeelctric" />
        </div>
      </section>
      </div>
    </React.Fragment>
  );
};

export default Partners;
