import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Button, Col, Tooltip } from "antd";
import mainlogo from "../assets/images/buildraudit-logo.png";
import ModalBox from "../Components/ModalBox/ModalBox";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import africa from "../assets/images/country/africa.png";
import Australia from "../assets/images/country/Australia.png";
import Dubai from "../assets/images/country/Dubai.png";
import india from "../assets/images/country/india.png";
import mexico from "../assets/images/country/mexico.png";
import Russia from "../assets/images/country/Russia.png";
import uk from "../assets/images/country/uk.png";
import usa from "../assets/images/country/usa.png";
import {
  AppstoreOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;


const Commercial = (
  <Menu>
    <Menu.Item key="0">
      <a target="" rel="" href="/">
        Luxury Retail
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="" href="/">
        Hospitality
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="/">
        HealthCare
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="/">
        Marine
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="#" rel="#" href="/">
        Aviation
      </a>
    </Menu.Item>
  </Menu>
);

const sector = (
  <Menu>
    <Menu.Item key="0">
      <a target="#" rel="#" href="/">
        Residential
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <li className="dropdown">
        <Dropdown overlay={Commercial}>
          <a
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            href
          >
            Commercial <DownOutlined />
          </a>
        </Dropdown>
      </li>
    </Menu.Item>
  </Menu>
);

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a target="" rel="#" href>
        <img
          src={india}
          style={{ width: "auto", height: "auto" }}
          alt="india"
        />
        - india
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img src={usa} style={{ width: "auto", height: "auto" }} alt="usa" />-
        America
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img
          src={africa}
          style={{ width: "auto", height: "auto" }}
          alt="africa"
        />
        - Africa
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img
          src={Australia}
          style={{ width: "auto", height: "auto" }}
          alt="australia"
        />
        - Australia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img
          src={Russia}
          style={{ width: "auto", height: "auto" }}
          alt="russia"
        />
        - Russia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img
          src={Dubai}
          style={{ width: "auto", height: "auto" }}
          alt="dubai"
        />
        - Dubai
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img
          src={mexico}
          style={{ width: "auto", height: "auto" }}
          alt="mexico"
        />
        - Mexico
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href>
        <img src={uk} style={{ width: "auto", height: "auto" }} alt="UK" />- UK
      </a>
    </Menu.Item>
  </Menu>
);

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      signinsignup: false,
      headerView: false,
      name: "",
    };
  }

  onCancel = () => {
    this.setState({ open: false });
    this.setState({ signinsignup: false });
  };
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="cta-header" id="cta-header">
          <Row align="center" style={{paddingTop:"5px"}} >
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              align="left"
            >
              <div className="book-jets">
                <a href="tel:+91 90352 26890">
                  <i className="flaticon-phone-call" />
                  +91 90352 26890
                </a>
                <span>or</span>
                <a href="mailto:info@buildraudits.com">
                  <i className="flaticon-email-1" /> info@buildraudits.com
                </a>
              </div>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              align="right"
            >
              <div className="social-media">
                <ul>
                  <li>
                    <Link>
                      <i className="flaticon-facebook-3" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-youtube-1" />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="main-hearder" id="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="logo" />{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 17, order: 2 }}
                  lg={{ span: 17, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/residential">Residential</Link>
                      </li>
                      <li>
                        <Link to="/commercial">Commercial</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li className="dropdown">
                        <Dropdown overlay={menu}>
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                            href
                          >
                            Countries <DownOutlined />
                          </a>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 3, order: 3 }}
                  lg={{ span: 3, order: 3 }}
                  align="right"
                >
                  <Tooltip title="login">
                    <Button
                      style={{
                        backgroundColor: "#2b3990",
                        color: "#ffff",
                        marginTop: "15px",
                        borderRadius: "10px",
                      }}
                      href="/signup"
                      className="search-button"
                      shape="square"
                      // icon={<SearchOutlined />}
                      size="large"
                      onClick="/signup"
                    >
                      <Link to="/signup"> Signin | Signup</Link>
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* Mobile-Header */}
        <div className="mobile-header" id="mobile-header">
          <div className="container mx-auto">
            <Row>
              <Col
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
              >
                <div className="left-section">
                  <div className="logo">
                    <Link to="/">
                      <img src={mainlogo} alt="Staging Depot" />{" "}
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="right"
              >
                <Menu
                  id="menu"
                  theme={this.state.theme}
                  onClick={this.handleClick}
                  style={{ width:"150px",position:"relative" }}
                  defaultOpenKeys={["sub1"]}
                  selectedKeys={[this.state.current]}
                  mode="inline"
                >
                  <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Nav-Menu" width="100%">
                    <Menu.Item key="5"><Link to="/">Home</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/about-us">About Us</Link></Menu.Item>
                    {/* <SubMenu key="sub3" title="Submenu">
                      <Menu.Item key="7">Option 7</Menu.Item>
                      <Menu.Item key="8">Option 8</Menu.Item>
                    </SubMenu> */}
                    <Menu.Item key="6"><Link to="/residential">Residential</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/commercial">Commercial</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/contact">Contact Us</Link></Menu.Item>
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </div>
        </div>

        <ModalBox
          content="test"
          visible={!!this.state.signinsignup}
          width={900}
          onCancel={this.onCancel}
          footer={null}
          destroyOnClose
        >
          <SigninSignup />
        </ModalBox>
      </React.Fragment>
    );
  }
}
