import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import InputBox from "../../Components/InputBox/InputBox";
import { Row, Col, Tabs, Button, Divider } from "antd";
import "./SigninSignup.scss";

const { TabPane } = Tabs;

class SigninSignup extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultActiveKey: [],
      mobileNumber: "",
      fullName: "",
      emailId: "",
      phoneNumber: "",
    };
  }
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    return (
      <React.Fragment>
       {/* start - page banners */}
       <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{fontSize:"45px"}}>sign-up</h1>
            <p>
              We'd Love To Hear From You Whether you have a question about
              pricing, need a site vist, re-filling, or anything, our team is
              ready to answer all your questions
            </p>
          </div>
        </section>
        {/* End - page banners */}
        <div className="signin-singup">
          <Row gutter={[12, 20]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <img
                src={require("../../assets/images/signin-signup.webp")}
                alt="Signin Singup"
              />
            </Col>
            <Col
              style={{ paddingLeft: "30px" }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="title">
                <h1 style={{ fontSize: "35px" }}>Welcome </h1>
                {/* <p>Select Your Location To Help Us Serve You Better!</p> */}
              </div>
              <Tabs defaultActiveKey="signup">
                <TabPane tab="Sign in" key="signin">
                  <p>
                    We will send you a verification code to your phone number
                  </p>
                  <form>
                    <div className="form-fields">
                      <InputBox
                        placeholder="Enter mobile number"
                        id="mobileNumber"
                        value={this.state.mobileNumber}
                        onChangeText={this.onChangeText}
                        type="text"
                      />
                    </div>
                    <div className="form-fields">
                      <Button type="primary">Continue</Button>
                    </div>
                  </form>
                  {/* <Divider>or Continue Using</Divider> */}
                  {/* <div className="social-login">
                    <Button type="secondary" className="google">
                      <img
                        src={require("../../assets/images/icons/google.svg")}
                        alt="google signin"
                      />{" "}
                      Google
                    </Button>
                    <Button type="secondary" className="facebook">
                      <img
                        src={require("../../assets/images/icons/facebook.svg")}
                        alt="Facebook signin"
                      />{" "}
                      Facebook
                    </Button>
                  </div> */}
                </TabPane>
                {/* signin tab ends */}

                <TabPane tab="Sign up" key="signup">
                  <form>
                    <div className="form-fields">
                      <InputBox
                        placeholder="Enter Full Name"
                        id="fullName"
                        value={this.state.fullName}
                        onChangeText={this.onChangeText}
                        type="text"
                      />
                    </div>
                    <div className="form-fields">
                      <InputBox
                        placeholder="Enter Email"
                        id="emailId"
                        value={this.state.emailId}
                        onChangeText={this.onChangeText}
                        type="email"
                      />
                    </div>
                    <div className="form-fields">
                      <InputBox
                        placeholder="Enter Phone Number"
                        id="phoneNumber"
                        value={this.state.phoneNumber}
                        onChangeText={this.onChangeText}
                        type="text"
                      />
                    </div>
                    <div className="form-fields">
                      <Button type="primary">Continue</Button>
                    </div>
                  </form>
                  <p>
                    We will send you a verification code on your phone number
                  </p>
                </TabPane>
                {/* signup tab ends */}
              </Tabs>
              {/* <div className="trade-member">
                                <p>Are you a trade group member?</p>
                                <Link>Register here</Link>
                            </div> */}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(SigninSignup);
